// useHideOnScroll.js

import { useEffect } from 'react';

function useHideOnScroll() {
    useEffect(() => {
        let lastScrollY = window.pageYOffset;
        const topBar = document.querySelector('.top-bar');
        let ticking = false;

        const handleScroll = () => {
            const currentScrollY = window.pageYOffset;

            if (currentScrollY <= 0) {
                // At the top of the page: always show the Top Bar
                topBar.classList.remove('hidden');
            } else if (currentScrollY > lastScrollY) {
                // Scrolling down: show the Top Bar
                topBar.classList.remove('hidden');
            } else if (currentScrollY < lastScrollY) {
                // Scrolling up: hide the Top Bar
                topBar.classList.add('hidden');
            }

            lastScrollY = currentScrollY > 0 ? currentScrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(handleScroll);
                ticking = true;
            }
        };

        const mediaQuery = window.matchMedia('(max-width: 768px)');
        const handleMediaChange = (e) => {
            if (e.matches) {
                window.addEventListener('scroll', onScroll);
            } else {
                window.removeEventListener('scroll', onScroll);
                topBar.classList.remove('hidden'); // Ensure Top Bar is visible on larger screens
            }
        };

        // Initial check
        handleMediaChange(mediaQuery);

        // Listen for changes in viewport size
        mediaQuery.addListener(handleMediaChange);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('scroll', onScroll);
            mediaQuery.removeListener(handleMediaChange);
        };
    }, []);
}

export default useHideOnScroll;
