// src/components/ConnectionStatus.js

import React from 'react';
import './ConnectionStatus.css'; // Import the corresponding CSS
import PropTypes from 'prop-types'; // For prop type checking
import { FaCheckCircle, FaTimesCircle, FaSyncAlt } from 'react-icons/fa'; // Importing icons from react-icons

function ConnectionStatus({ status }) {
  let statusClass = '';

  switch (status) {
    case 'connected':
      statusClass = 'connected';
      break;
    case 'disconnected':
      statusClass = 'disconnected';
      break;
    case 'pending':
      statusClass = 'pending';
      break;
    default:
      statusClass = 'disconnected';
  }

  // Choose the appropriate icon based on status
  const renderIcon = () => {
    switch (status) {
      case 'connected':
        return <FaCheckCircle />;
      case 'disconnected':
        return <FaTimesCircle />;
      case 'pending':
        return <FaSyncAlt className="spin" />; // Adding a spin class for animation
      default:
        return <FaTimesCircle />;
    }
  };

  return (
    <div
      className={`connection-status ${statusClass}`}
      role="status"
      aria-live="polite"
      aria-label={`Connection status: ${status}`}
    >
      <span className="status-icon" aria-hidden="true">
        {renderIcon()}
      </span>
    </div>
  );
}

ConnectionStatus.propTypes = {
  status: PropTypes.oneOf(['connected', 'pending', 'disconnected']).isRequired,
};

export default ConnectionStatus;
