import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { AuthContext } from './AuthContext'; // Adjust the path as necessary
import { apiFetch } from '../utils/api'; // Import the apiFetch utility
import { toast } from 'react-toastify'; // Assuming you use react-toastify for notifications

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
  const wsRef = useRef(null);
  const messageQueueRef = useRef([]);

  // States managed by WebSocketContext
  const [notifications, setNotifications] = useState([]);
  const [activities, setActivities] = useState([]);
  const [feedItems, setFeedItems] = useState([]);
  const [liveFeedItems, setLiveFeedItems] = useState([]);
  const [cacheBustValues, setCacheBustValues] = useState({}); 
  const [error, setError] = useState(null); 

  // Access AuthContext
  const { user, setUser } = useContext(AuthContext); 
  const [currentUserId, setCurrentUserId] = useState(null);

  const handleWebSocketMessage = useCallback(
    (data) => {
      switch (data.type) {
        case 'direct_message':
          setNotifications((prevNotifications) => [
            {
              id: data.payload.id,
              message: `${data.payload.sender} sent you a message: ${data.payload.content}`,
              timestamp: new Date(data.payload.timestamp).toLocaleString(),
              read: false,
            },
            ...prevNotifications,
          ]);
          toast.info(`New Message from ${data.payload.sender}`);
          break;
        case 'broadcast':
          setFeedItems((prevFeed) => [data.payload, ...prevFeed]);
          toast.info(`Broadcast: ${data.payload.content}`);
          break;
        case 'activity_update':
          setActivities((prevActivities) => [data.payload, ...prevActivities]);
          break;
        case 'new_comment':
          const newComment = data.payload;
          setNotifications((prevNotifications) => [
            {
              id: newComment.id,
              message: `${newComment.userEmail} commented: ${newComment.content}`,
              timestamp: new Date(newComment.timestamp).toLocaleString(),
              read: false,
            },
            ...prevNotifications,
          ]);
          toast.info(`New Comment: ${newComment.userEmail} commented on your post.`);
          break;
        case 'new_notification':
          setNotifications((prevNotifications) => [
            {
              id: data.payload.id,
              title: data.payload.title,
              message: data.payload.message,
              timestamp: new Date(data.payload.timestamp).toLocaleString(),
              read: data.payload.read,
            },
            ...prevNotifications,
          ]);
          toast.info(`New Notification: ${data.payload.title}`);
          break;
        case 'update_user':
          if (data.payload && data.payload.user) {
            setUser(data.payload.user);
          }
          break;
        case 'avatar_update':
          const { userId, cacheBustValue } = data.payload;
          setCacheBustValues((prevValues) => ({
            ...prevValues,
            [userId]: cacheBustValue,
          }));
          break;
        default:
          console.warn('WebSocketContext: Unhandled message type:', data.type);
      }
    },
    [setUser]
  );

  const fetchInitialData = useCallback(async () => {
    try {
      const initialNotifications = await apiFetch('notifications', { method: 'GET' });
      setNotifications(
        initialNotifications.map((notif) => ({
          id: notif.id,
          title: notif.title,
          message: notif.message,
          timestamp: new Date(notif.timestamp).toLocaleString(),
          read: notif.read,
        }))
      );

      const initialActivities = await apiFetch('activities', { method: 'GET' });
      setActivities(initialActivities);

      const initialFeed = await apiFetch('feed', { method: 'GET' });
      setFeedItems(initialFeed);

      const initialLiveFeed = await apiFetch('live-feed', { method: 'GET' });
      setLiveFeedItems(initialLiveFeed);
    } catch (err) {
      console.error('WebSocketContext: Error fetching initial data:', err.message);
      toast.error('Failed to load initial data.');
    }
  }, []);

  const reconnectAttempts = useRef(0);
  const reconnectTimer = useRef(null);
  const maxReconnectAttempts = 10;
  const baseReconnectInterval = 5000;

  const establishWebSocketConnection = useCallback(async () => {
    if (!currentUserId) {
      console.log('WebSocketContext: No authenticated user. Skipping WebSocket connection.');
      return;
    }

    try {
      const socketUrl = `${websocketUrl}?userId=${currentUserId}`;
      console.log(`WebSocketContext: Connecting to WebSocket at ${socketUrl}`);
      const socket = new WebSocket(socketUrl);

      wsRef.current = socket;

      socket.onopen = () => {
        console.log('WebSocketContext: WebSocket connection established.');
        reconnectAttempts.current = 0;

        if (messageQueueRef.current.length > 0) {
          messageQueueRef.current.forEach((queuedMessage) => {
            socket.send(JSON.stringify(queuedMessage));
          });
          messageQueueRef.current = [];
        }
      };

      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          handleWebSocketMessage(data);
        } catch (error) {
          console.error('WebSocketContext: Error parsing WebSocket message:', error);
        }
      };

      socket.onerror = (errorEvent) => {
        console.error('WebSocketContext: WebSocket encountered an error:', errorEvent);
      };

      socket.onclose = (event) => {
        console.warn(`WebSocketContext: WebSocket closed: ${event.reason}`);

        if (reconnectAttempts.current < maxReconnectAttempts) {
          const reconnectInterval = baseReconnectInterval * Math.pow(2, reconnectAttempts.current);
          console.log(
            `WebSocketContext: Attempting to reconnect in ${reconnectInterval / 1000} seconds...`
          );
          reconnectAttempts.current += 1;
          reconnectTimer.current = setTimeout(() => {
            establishWebSocketConnection();
          }, reconnectInterval);
        } else {
          console.error('WebSocketContext: Max reconnection attempts reached. Could not reconnect.');
          setError('Could not reconnect to WebSocket.');
          toast.error('Unable to maintain real-time connection.');
        }
      };
    } catch (error) {
      console.error('WebSocketContext: Error establishing WebSocket connection:', error);
      setError('Failed to establish WebSocket connection.');
      toast.error('Failed to establish real-time connection.');
    }
  }, [currentUserId, handleWebSocketMessage, websocketUrl]);

  useEffect(() => {
    const initialize = async () => {
      if (currentUserId) {
        await fetchInitialData();
        establishWebSocketConnection();
      }
    };

    initialize();

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        console.log('WebSocketContext: WebSocket connection closed.');
      }
      if (reconnectTimer.current) {
        clearTimeout(reconnectTimer.current);
        console.log('WebSocketContext: Reconnection timer cleared.');
      }
    };
  }, [currentUserId, establishWebSocketConnection, fetchInitialData]);

  const sendMessage = useCallback((message) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(message));
    } else {
      messageQueueRef.current.push(message);
    }
  }, []);

  // Add a function to send an avatar_update message to the server
  const sendAvatarUpdate = useCallback((cacheBustValue) => {
    // Ensure the user is logged in and we have a userId
    if (currentUserId) {
      sendMessage({
        type: 'avatar_update',
        cacheBustValue: cacheBustValue,
      });
    }
  }, [currentUserId, sendMessage]);

  useEffect(() => {
    if (user && user.id) {
      setCurrentUserId(user.id);
    } else {
      setCurrentUserId(null);
    }
  }, [user]);

  const contextValue = useMemo(
    () => ({
      wsRef,
      sendMessage,
      sendAvatarUpdate, // Include the new function in the context
      notifications,
      setNotifications,
      activities,
      setActivities,
      feedItems,
      setFeedItems,
      liveFeedItems,
      setLiveFeedItems,
      currentUserId,
      setCurrentUserId,
      error,
      cacheBustValues,
    }),
    [
      notifications,
      activities,
      feedItems,
      liveFeedItems,
      currentUserId,
      setCurrentUserId,
      error,
      sendMessage,
      sendAvatarUpdate,
      cacheBustValues,
    ]
  );

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};
