// src/context/AuthContext.js

import React, { createContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiFetch } from '../utils/api'; // Import the apiFetch utility

// Create the Auth Context
export const AuthContext = createContext();

// Utility function to normalize user data
const normalizeUser = (data) => {
  const userData = data.user || data;
  if (userData.id && userData.email) {
    return {
      id: userData.id,
      email: userData.email,
      isEmailVerified: userData.isEmailVerified,
      avatarUrl: userData.avatarUrl,
      firstName: userData.firstName,
      lastName: userData.lastName,
      bio: userData.bio,
    };
  } else {
    return null;
  }
};

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // User information
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  // Function to check authentication status and fetch user info
  const checkAuth = async () => {
    try {
      const data = await apiFetch('user-info', {
        method: 'GET',
      });
      console.log('AuthContext: Received user data:', data);
      const normalizedUser = normalizeUser(data);
      console.log('AuthContext: Normalized user data:', normalizedUser);
      setUser(normalizedUser);
    } catch (error) {
      if (error.status === 401 || error.errorCode === 'UNAUTHORIZED') {
        // User is not authenticated; handle this silently
        console.log('AuthContext: User not authenticated.');
        setUser(null);
      } else {
        // Handle other errors
        console.error('AuthContext: Unexpected error fetching user info:', error.message);
        setUser(null);
      }
    } finally {
      setLoading(false);
    }
  };

  // Check authentication status on component mount
  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to log the user in
  const login = async (credentials) => {
    try {
      const data = await apiFetch('login', {
        method: 'POST',
        body: credentials,
      });
      console.log('AuthContext: Login successful, received data:', data);
      // Fetch user info after successful login
      await checkAuth();
      navigate('/dashboard'); // Redirect to dashboard after login
      return { success: true };
    } catch (error) {
      console.error('AuthContext: Login failed:', error.message);
      // Check if the error is due to unverified email based on errorCode
      if (error.errorCode === 'UNVERIFIED_EMAIL') {
        return { success: false, error: 'unverified_email' }; // Use a consistent code
      }
      return { success: false, error: error.message };
    }
  };

  // Function to sign up a new user
  const signup = async (formData) => {
    try {
      const data = await apiFetch('create_account', {
        method: 'POST',
        body: formData,
      });
      console.log('AuthContext: Signup successful, received data:', data);
      // Fetch user info after successful signup
      await checkAuth();
      navigate('/dashboard'); // Redirect after successful signup
      return { success: true };
    } catch (error) {
      console.error('AuthContext: Signup failed:', error.message);
      return { success: false, error: error.message };
    }
  };

  // Function to log the user out
  const logout = async () => {
    try {
      await apiFetch('logout', {
        method: 'POST',
      });
      console.log('AuthContext: Logout successful.');
      setUser(null);
      navigate('/login'); // Redirect to login page
    } catch (error) {
      console.error('AuthContext: Logout failed:', error.message);
      // Optionally, display a toast or notification
    }
  };

  // Function to resend verification email
  const resendVerificationEmail = async (email) => {
    try {
      const data = await apiFetch('resend_verification_email', {
        method: 'POST',
        body: { email },
      });
      console.log('AuthContext: Verification email resent:', data);
      return { success: true, message: data.message };
    } catch (error) {
      console.error('AuthContext: Resend verification failed:', error.message);
      return { success: false, error: error.message };
    }
  };

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
      signup,
      resendVerificationEmail, // Expose the resend function
      loading,
    }),
    [user, loading]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
