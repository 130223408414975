// src/components/UserFeed.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiFetch } from '../utils/api'; // Import apiFetch
import './UserFeed.css'; // Import custom styles
import { toast, ToastContainer } from 'react-toastify'; // Import Toastify for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

function UserFeed() {
  const { id } = useParams(); // Get user ID from URL
  const [feedItems, setFeedItems] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(''); // Error state

  useEffect(() => {
    const fetchFeed = async () => {
      if (!id) {
        setError('No user ID provided.');
        setLoading(false);
        toast.error('No user ID provided.');
        return;
      }

      setLoading(true);
      setError('');

      try {
        // Make the API call using apiFetch
        const data = await apiFetch(`feed/${id}`);
        setFeedItems(data); // Assuming backend sends data as an array of feed items
        toast.success('Feed loaded successfully.');
      } catch (err) {
        console.error('Error fetching feed:', err);
        if (err.status === 401) {
          // If unauthorized, redirect to login
          window.location.href = '/login';
        } else if (err.status === 404) {
          setError('Feed not found.');
          toast.error('Feed not found.');
        } else {
          setError(err.message || 'Failed to fetch feed.');
          toast.error(err.message || 'Failed to fetch feed.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFeed();
  }, [id]);

  if (loading) {
    return (
      <div className="user-feed-page">
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Loading feed...</p>
        </div>
        <ToastContainer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="user-feed-page">
        <div className="error-container">
          <p className="error-message">{error}</p>
          <button onClick={() => window.location.href = '/inbox'} className="btn btn-secondary">
            Back to Inbox
          </button>
        </div>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="user-feed-page">
      <h2>User Feed</h2>
      {feedItems.length === 0 ? (
        <p>No feed items available.</p>
      ) : (
        <div className="feed-items">
          {feedItems.map((item) => (
            <div key={item.id} className="feed-item">
              <div className="feed-header">
                {item.authorAvatarUrl && (
                  <img
                    src={item.authorAvatarUrl}
                    alt={`${item.authorName}'s avatar`}
                    className="feed-avatar"
                  />
                )}
                <div>
                  <h3>{item.authorName}</h3>
                  <p>{new Date(item.timestamp).toLocaleString()}</p>
                </div>
              </div>
              <p className="feed-content">{item.content}</p>
              {item.imageUrl && (
                <img
                  src={item.imageUrl}
                  alt="Feed content"
                  className="feed-image"
                />
              )}
              <div className="feed-actions">
                <span>👍 {item.likesCount}</span>
                <span>💬 {item.commentsCount}</span>
                <span>🔄 {item.sharesCount}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default UserFeed;
