// src/utils/api.js

export const apiFetch = async (endpoint, options = {}) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const headers = {
    ...(options.headers || {}),
  };

  // If the request has a body and it's not FormData, set Content-Type
  if (options.body && !(options.body instanceof FormData)) {
    headers['Content-Type'] = 'application/json';
  }

  const config = {
    credentials: 'include', // Include credentials for cross-origin requests if necessary
    ...options,
    headers,
    body:
      options.body instanceof FormData
        ? options.body
        : options.body
        ? JSON.stringify(options.body)
        : undefined,
  };

  try {
    const response = await fetch(`${apiUrl}/${endpoint}`, config);

    // Read the response body once
    const contentType = response.headers.get('content-type');
    let data = null;

    if (contentType && contentType.includes('application/json')) {
      data = await response.json().catch(() => null);
    } else {
      data = await response.text().catch(() => null);
    }

    if (!response.ok) {
      // Default error values
      let errorMessage = 'API request failed';
      let errorCode = 'API_ERROR';
      let errorDetails = '';

      if (data) {
        if (typeof data === 'object') {
          errorMessage = data.message || errorMessage;
          errorCode = data.errorCode || errorCode;
          errorDetails = JSON.stringify(data);
        } else if (typeof data === 'string') {
          errorMessage = data || errorMessage;
          errorDetails = data;
        }
      }

      console.error(`Error ${response.status} (${response.statusText}): ${errorDetails}`);

      // Create a standardized Error object
      const error = new Error(errorMessage);
      error.errorCode = errorCode;
      error.status = response.status;
      throw error;
    }

    // If response is JSON, return the parsed data; otherwise, return an empty object
    if (contentType && contentType.includes('application/json')) {
      return data;
    } else {
      return {}; // Return an empty object if no JSON
    }
  } catch (error) {
    // If the error is already an instance of Error, rethrow it
    if (error instanceof Error) {
      throw error;
    } else {
      // Otherwise, create a new Error instance
      const newError = new Error('An unknown error occurred.');
      newError.originalError = error;
      throw newError;
    }
  }
};
