// src/components/FeatureCard.js

import React from 'react';
import './FeatureCard.css'; // Updated CSS filename for clarity
import { useNavigate } from 'react-router-dom';

function FeatureCard({ title, description, image, link, delay }) {
  const navigate = useNavigate();

  return (
    <div
      className={`feature-card section-animate ${delay}`}
      data-aos="fade-up"
    >
      <div className="feature-image">
        <img src={image} alt={`${title} illustration`} />
      </div>
      <div className="feature-content">
        <h2>{title}</h2>
        <p>{description}</p>
        <button
          className="feature-button"
          onClick={() => navigate(link)}
          aria-label={`Learn more about ${title}`}
        >
          Learn More
        </button>
      </div>
    </div>
  );
}

export default FeatureCard;
