// src/context/ThemeContext.js

import React, { createContext, useState, useEffect } from 'react';

// Create the ThemeContext
export const ThemeContext = createContext();

// Create the ThemeProvider component
export const ThemeProvider = ({ children }) => {
  // Function to get the initial theme from localStorage or default to 'light'
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme : 'light';
  };

  const [theme, setTheme] = useState(getInitialTheme());

  // Apply the theme class to the body and save it to localStorage
  useEffect(() => {
    document.body.classList.remove('light-theme', 'dark-theme'); // Remove existing theme classes
    document.body.classList.add(`${theme}-theme`);
    localStorage.setItem('theme', theme);
    console.log(`Applied theme: ${theme}-theme`);
  }, [theme]);

  // Function to toggle the theme
  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light';
      console.log(`Theme toggled to: ${newTheme}`);
      return newTheme;
    });
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
