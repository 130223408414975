import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './Login.css'; // Import custom styles
import { toast } from 'react-toastify'; // Import toast for notifications
import { apiFetch } from '../utils/api'; // Import the apiFetch utility

function Login() {
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const { login: authLogin } = useContext(AuthContext); // Access the login function from AuthContext

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage('');
    setIsSubmitting(true);

    try {
      const result = await authLogin(loginData);
      if (result.success) {
        toast.success('Logged in successfully!');
        navigate('/dashboard');
      } else {
        setResponseMessage(result.error || 'Failed to login.');
        toast.error(result.error || 'Failed to login.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setResponseMessage(error.message || 'An error occurred. Please try again.');
      toast.error(error.message || 'An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePasswordReset = async () => {
    // Attempt to initiate a password reset request
    setResponseMessage('');
    setIsSubmitting(true);

    try {
      const response = await apiFetch('password-reset', {
        method: 'POST',
        body: { email: loginData.email },
      });
      toast.success(response.message || 'If the email exists, a password reset link has been sent.');
    } catch (error) {
      console.error('Error during password reset:', error);
      const errorMsg = error.message || 'An error occurred. Please try again.';
      setResponseMessage(errorMsg);
      toast.error(errorMsg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit} className="login-form" aria-label="Login Form">
        <h2>Login</h2>
        
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={loginData.email}
          onChange={handleChange}
          required
          placeholder="Enter your email"
          aria-required="true"
        />

        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={loginData.password}
          onChange={handleChange}
          required
          placeholder="Enter your password"
          aria-required="true"
        />

        <button
          type="submit"
          className="btn btn-primary"
          disabled={isSubmitting}
          aria-disabled={isSubmitting}
        >
          {isSubmitting ? 'Logging in...' : 'Login'}
        </button>

        {/* New Reset Password Button */}
        <button
          type="button"
          className="btn btn-secondary"
          disabled={isSubmitting || !loginData.email} 
          onClick={handlePasswordReset}
          aria-disabled={isSubmitting || !loginData.email}
        >
          {isSubmitting ? 'Processing...' : 'Reset Password'}
        </button>

        {responseMessage && (
          <p
            className={`response ${responseMessage.toLowerCase().includes('error') ? 'error' : 'success'}`}
            role="alert"
          >
            {responseMessage}
          </p>
        )}
      </form>
    </div>
  );
}

export default Login;
