// src/components/ComposeMessage.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ComposeMessage.css';
import { AuthContext } from '../context/AuthContext';
import { apiFetch } from '../utils/api'; // Import the apiFetch utility

function ComposeMessage() {
  const { userId } = useParams(); // Extract userId from the route parameters
  const [receiverId, setReceiverId] = useState(userId || '');
  const [content, setContent] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { user } = useContext(AuthContext); // Access the user from AuthContext

  // Fetch receiver's details whenever receiverId changes
  useEffect(() => {
    const fetchReceiverDetails = async () => {
      if (!receiverId) return; // No receiverId provided

      setLoading(true);
      setError('');

      try {
        // Use the relative endpoint without the base URL
        const data = await apiFetch(`api/users/${receiverId}`, {
          method: 'GET',
        });

        if (data.user && data.user.name) {
          setReceiverName(data.user.name); // Assuming API returns { user: { name: '...', ... } }
          setError(''); // Clear any previous errors
        } else {
          setReceiverName(receiverId); // Fallback to receiverId if name not available
          setError('Receiver name not found.');
        }
      } catch (err) {
        console.error('Error fetching receiver details:', err);
        setError(err.message || 'An unexpected error occurred while fetching receiver details.');
        setReceiverName(receiverId); // Fallback to receiverId in case of error
      } finally {
        setLoading(false);
      }
    };

    fetchReceiverDetails();
  }, [receiverId]);

  // Handle adding a new message using apiFetch
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError('You are not logged in. Please log in.');
      navigate('/login');
      return;
    }

    if (receiverId.trim() === '' || content.trim() === '') {
      setError('Both receiver and message content are required.');
      return;
    }

    setLoading(true);
    setError('');

    const postPayload = {
      receiverId: receiverId.trim(),
      content: content.trim(),
    };

    try {
      // Use the relative endpoint without the base URL
      const data = await apiFetch('api/messages/send', {
        method: 'POST',
        body: postPayload, // apiFetch handles JSON.stringify
      });

      if (data.message) {
        // Optionally, you can display a success message or perform additional actions
        navigate('/inbox'); // Redirect to Inbox after successful send
      } else {
        throw new Error('Unexpected response format.');
      }
    } catch (err) {
      console.error('Error sending message:', err);
      setError(err.message || 'An unexpected error occurred while sending the message.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="compose-message-page">
      <div className="compose-message-container">
        <h2>Compose Message</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit} className="compose-message-form">
          <label htmlFor="receiver">
            To:
            {userId ? (
              <input
                type="text"
                id="receiver"
                value={receiverName || receiverId}
                readOnly
                className="receiver-input"
              />
            ) : (
              <input
                type="text"
                id="receiver"
                value={receiverId}
                onChange={(e) => setReceiverId(e.target.value)}
                placeholder="Enter recipient's ID"
                required
              />
            )}
          </label>
          <label htmlFor="message-content">
            Message:
            <textarea
              id="message-content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Type your message here..."
              required
            ></textarea>
          </label>
          <button type="submit" disabled={loading} aria-label="Send message">
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ComposeMessage;
