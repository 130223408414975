import React, { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiFetch } from '../utils/api'; // Import apiFetch
import { AuthContext } from '../context/AuthContext'; // Import AuthContext
import { WebSocketContext } from '../context/WebSocketContext'; // Import WebSocketContext
import { toast, ToastContainer } from 'react-toastify'; // Import Toastify for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import './UserProfile.css'; // Import custom styles

function UserProfile() {
  const { user, loading: authLoading, setUser } = useContext(AuthContext); 
  const { sendAvatarUpdate } = useContext(WebSocketContext); // Access sendAvatarUpdate from WebSocketContext
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: '',
    dateOfBirth: '',
    bio: '',
    avatarUrl: '',
  });
  const [loading, setLoading] = useState(true); 
  const [errors, setErrors] = useState({});
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (authLoading) return;

    if (!user) {
      navigate('/login');
      return;
    }

    const fetchUserProfile = async () => {
      setLoading(true);
      try {
        const data = await apiFetch('user-profile', {
          method: 'GET',
        });

        setProfile({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          phoneNumber: data.phoneNumber || '',
          address: data.address || '',
          dateOfBirth: data.dateOfBirth ? data.dateOfBirth.split('T')[0] : '',
          bio: data.bio || '',
          avatarUrl: data.avatarUrl || '',
        });
        setAvatarPreview(data.avatarUrl || '/default-avatar.png');
        toast.success('Profile loaded successfully!');
      } catch (err) {
        console.error(err);
        toast.error(err.message || 'Error fetching profile');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [user, navigate, authLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: '',
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!profile.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!profile.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (profile.phoneNumber && !/^\+?[1-9]\d{1,14}$/.test(profile.phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number format';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        toast.error('Please select a valid image file.');
        return;
      }
      setAvatarFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      toast.error('Please fix the errors in the form.');
      return;
    }

    const formData = new FormData();
    formData.append('firstName', profile.firstName.trim());
    formData.append('lastName', profile.lastName.trim());
    formData.append('phoneNumber', profile.phoneNumber.trim());
    formData.append('address', profile.address.trim());
    formData.append('dateOfBirth', profile.dateOfBirth || '');
    formData.append('bio', profile.bio.trim());
    if (avatarFile) {
      formData.append('avatar', avatarFile);
    }

    try {
      const data = await apiFetch('user-profile', {
        method: 'PUT',
        body: formData,
      });

      if (data.user) {
        setUser(data.user); 
      } else {
        try {
          const refreshedData = await apiFetch('user-info', { method: 'GET' });
          setUser(refreshedData);
        } catch (refreshError) {
          console.error('Error fetching refreshed user info:', refreshError);
          toast.error('Profile updated but failed to refresh user info.');
        }
      }

      toast.success('Profile updated successfully!');

      // If an avatar was updated, send avatar_update message with a unique cacheBustValue
      if (avatarFile) {
        const cacheBustValue = Date.now().toString(); 
        sendAvatarUpdate(cacheBustValue);
      }

      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(error.message || 'An error occurred. Please try again.');
    }
  };

  if (authLoading || loading) {
    return (
      <div className="user-profile">
        <div className="loading">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="user-profile">
      <div className="profile-header">
        <h1>Edit Profile</h1>
      </div>

      <ToastContainer />
      <form onSubmit={handleSubmit} className="profile-form" aria-label="Edit Profile Form">
        <div className="avatar-section">
          <img
            src={avatarPreview || '/default-avatar.png'}
            alt="User Avatar"
            className="avatar-preview"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
            aria-label="Upload avatar"
          />
          <button
            type="button"
            className="btn btn-secondary upload-avatar-button"
            onClick={() => fileInputRef.current.click()}
            aria-label="Change Avatar"
          >
            Change Avatar
          </button>
        </div>

        <div className="form-group">
          <label htmlFor="firstName">
            First Name<span className="required">*</span>:
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={profile.firstName}
            onChange={handleChange}
            aria-required="true"
            aria-invalid={errors.firstName ? 'true' : 'false'}
            placeholder="Enter your first name"
          />
          {errors.firstName && <span className="error">{errors.firstName}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="lastName">
            Last Name<span className="required">*</span>:
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={profile.lastName}
            onChange={handleChange}
            aria-required="true"
            aria-invalid={errors.lastName ? 'true' : 'false'}
            placeholder="Enter your last name"
          />
          {errors.lastName && <span className="error">{errors.lastName}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={profile.phoneNumber}
            onChange={handleChange}
            placeholder="+1234567890"
            aria-invalid={errors.phoneNumber ? 'true' : 'false'}
          />
          {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={profile.address}
            onChange={handleChange}
            placeholder="Enter your address"
          />
        </div>

        <div className="form-group">
          <label htmlFor="dateOfBirth">Date of Birth:</label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={profile.dateOfBirth}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="bio">Bio:</label>
          <textarea
            id="bio"
            name="bio"
            value={profile.bio}
            onChange={handleChange}
            placeholder="Tell us something about yourself..."
            rows="4"
          ></textarea>
        </div>

        <button type="submit" className="btn btn-primary save-button" disabled={loading}>
          {loading ? 'Saving...' : 'Save Changes'}
        </button>
      </form>
    </div>
  );
}

export default UserProfile;
