// src/App.js

import React from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import SearchResults from './components/SearchResults';
import UserProfile from './components/UserProfile';
import Inbox from './components/Inbox';
import MessageDetail from './components/MessageDetail';
import ComposeMessage from './components/ComposeMessage';
import PrivateRoute from './components/PrivateRoute';
import Profile from './components/Profile';
import UserFeed from './components/UserFeed';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import About from './components/About';
import Feature from './components/FeatureCard';
import ConnectionRequests from './pages/ConnectionRequests';
import SharedFeed from './components/SharedFeed';
import AcceptedConnections from './components/AcceptedConnections';
import ProtectedLayout from './components/ProtectedLayout';
import ResetPassword from './components/ResetPassword';

function App() {
  const navigate = useNavigate(); // Initialize navigate hook

  // Define handler functions for ProtectedLayout props
  const handleEditProfile = () => {
    navigate('/user-profile');
  };

  const handleInbox = () => {
    navigate('/inbox');
  };

  const handleConnections = () => {
    navigate('/accepted-connections');
  };

  const handleConnectionRequest = () => {
    navigate('/connection-requests');
  };

  const handleSearchUsers = (query) => {
    // Navigate to the SearchResults route with the query as a URL parameter
    navigate(`/search-results?query=${encodeURIComponent(query)}`); // Changed 'q' to 'query'
  };

  // Determine whether to show toast notifications
  const enableToasts = process.env.REACT_APP_ENABLE_TOASTS === 'true';

  return (
    <div>
      {/* Conditionally render ToastContainer based on enableToasts */}
      {enableToasts && <ToastContainer />}

      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="/features" element={<Feature />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <ProtectedLayout
                onEditProfile={handleEditProfile}
                onInbox={handleInbox}
                onConnections={handleConnections}
                onConnectionRequest={handleConnectionRequest}
                onSearchUsers={handleSearchUsers}
                // Removed onLogout, onToggleTheme, and currentTheme props
              />
            </PrivateRoute>
          }
        >
          {/* Nested Protected Routes */}
          <Route path="feed/:id" element={<UserFeed />} />
          <Route path="accepted-connections" element={<AcceptedConnections />} />
          <Route path="shared-feed/:userId" element={<SharedFeed />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="connection-requests" element={<ConnectionRequests />} />
          <Route path="search-results" element={<SearchResults />} />
          <Route path="account/:id" element={<UserProfile />} />
          <Route path="user-profile" element={<UserProfile />} />
          <Route path="inbox" element={<Inbox />} />
          <Route path="messages/:messageId" element={<MessageDetail />} />
          <Route path="compose" element={<ComposeMessage />} />
        </Route>

        {/* Redirect any unknown routes to home */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
