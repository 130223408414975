// src/components/AcceptedConnections.js

import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './AcceptedConnections.module.css'; // Importing CSS Module
import { AuthContext } from '../context/AuthContext';
import { WebSocketContext } from '../context/WebSocketContext'; // Import WebSocketContext
import { apiFetch } from '../utils/api'; // Import the apiFetch utility
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageWithFallback from './ImageWithFallback'; // Import ImageWithFallback

function AcceptedConnections() {
  const { user, loading: authLoading } = useContext(AuthContext); // Access 'user' and 'loading' from AuthContext
  const { cacheBustValues } = useContext(WebSocketContext); // Access cacheBustValues from WebSocketContext
  const navigate = useNavigate();
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // State variables for modal functionality
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState('');
  const messageTextareaRef = useRef(null);

  // Function to fetch accepted connections
  const fetchConnections = useCallback(async () => {
    if (authLoading) {
      return;
    }

    if (!user) {
      toast.error('You are not logged in. Please log in.');
      setLoading(false);
      navigate('/login');
      return;
    }

    try {
      const data = await apiFetch('connections/accepted', {
        method: 'GET',
      });
      setConnections(data.acceptedConnections || []);
      console.log('Fetched accepted connections:', data.acceptedConnections);
    } catch (err) {
      console.error('Error fetching accepted connections:', err.message);
      setError(err.message);
      if (err.message === 'Unauthorized') {
        navigate('/login');
      } else {
        toast.error(err.message || 'Failed to fetch connections.');
      }
    } finally {
      setLoading(false);
    }
  }, [user, navigate, authLoading]);

  useEffect(() => {
    fetchConnections();
  }, [fetchConnections]);

  const handleViewProfile = useCallback(
    (userId) => {
      navigate(`/profile/${userId}`);
    },
    [navigate]
  );

  const handleOpenSendMessageModal = useCallback((user) => {
    setSelectedUser(user);
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setSelectedUser(null);
    setMessage('');
  }, []);

  const handleSendMessage = useCallback(async () => {
    if (!message.trim()) {
      toast.warn('Message cannot be empty.');
      return;
    }

    try {
      await apiFetch('messages/send', {
        method: 'POST',
        body: {
          recipientEmail: selectedUser.email,
          content: message.trim(),
        },
      });
      toast.success('Message sent successfully!');
      handleCloseModal();
    } catch (error) {
      console.error('Error sending message:', error.message);
      setError(error.message);
      toast.error(`Error: ${error.message}`);
      if (error.message === 'Unauthorized') {
        navigate('/login');
      }
    }
  }, [message, selectedUser, navigate, handleCloseModal]);

  useEffect(() => {
    if (showModal && messageTextareaRef.current) {
      messageTextareaRef.current.focus();
    }
  }, [showModal]);

  // Monitor cacheBustValues updates
  useEffect(() => {
    console.log('AcceptedConnections: cacheBustValues updated:', cacheBustValues);
    Object.keys(cacheBustValues).forEach((key) => {
      console.log(`UserID: ${key}, cacheBustValue: ${cacheBustValues[key]}`);
    });
  }, [cacheBustValues]);

  if (authLoading || loading) {
    return (
      <div className={styles.acceptedConnectionsPage}>
        <div className={styles.acceptedConnectionsContainer}>
          <div className={styles.loading}>
            <div className={styles.spinner}></div>
            <p>Loading accepted connections...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.acceptedConnectionsPage}>
        <div className={styles.acceptedConnectionsContainer}>
          <p className={styles.errorMessage}>{error}</p>
        </div>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
      </div>
    );
  }

  if (connections.length === 0) {
    return (
      <div className={styles.acceptedConnectionsPage}>
        <div className={styles.acceptedConnectionsContainer}>
          <h2 className={styles.acceptedConnectionsHeader}>My Accepted Connections</h2>
          <p>You have no accepted connections yet.</p>
        </div>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
      </div>
    );
  }

  return (
    <div className={styles.acceptedConnectionsPage}>
      <div className={styles.acceptedConnectionsContainer}>
        <h2 className={styles.acceptedConnectionsHeader}>My Accepted Connections</h2>
        <ul className={styles.connectionsList}>
          {connections.map((connUser) => {
            const userIdStr = String(connUser.id);
            const cacheBustValue = cacheBustValues[userIdStr] || '';
            const imageUrl = connUser.avatarUrl
              ? `${connUser.avatarUrl}?cacheBust=${cacheBustValue}`
              : '/default-avatar.jpg';

            console.log(
              `Rendering connection: ${connUser.id}, URL: ${imageUrl}, cacheBustValue: ${cacheBustValue}`
            );

            return (
              <li key={connUser.id} className={styles.connectionItem}>
                <div
                  className={styles.userInfo}
                  onClick={() => handleViewProfile(connUser.id)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') handleViewProfile(connUser.id);
                  }}
                >
                  <ImageWithFallback
                    src={imageUrl}
                    fallbackSrc="/default-avatar.jpg"
                    alt={`${connUser.name}'s avatar`}
                    className={styles.connectionAvatar}
                  />
                  <div className={styles.connectionDetails}>
                    <h3 className={styles.connectionName}>{connUser.name}</h3>
                    <p className={styles.connectionEmail}>{connUser.email}</p>
                  </div>
                </div>
                <button
                  className={styles.sendMessageButton}
                  onClick={() => handleOpenSendMessageModal(connUser)}
                  aria-label={`Send message to ${connUser.name}`}
                >
                  Send Message
                </button>
              </li>
            );
          })}
        </ul>
      </div>

      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />

      {showModal && selectedUser && (
        <div
          className={styles.modalOverlay}
          role="dialog"
          aria-modal="true"
          aria-labelledby="send-message-title"
          onClick={handleCloseModal}
        >
          <div
            className={styles.modal}
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={styles.closeModalButton}
              onClick={handleCloseModal}
              aria-label="Close message modal"
            >
              &times;
            </button>
            <h2 id="send-message-title">Send a Message to {selectedUser.name}</h2>
            <textarea
              ref={messageTextareaRef}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              maxLength={1000}
              rows={5}
              aria-label={`Message to ${selectedUser.name}`}
              className={styles.messageTextarea}
            ></textarea>
            <div className={styles.modalButtons}>
              <button
                onClick={handleSendMessage}
                disabled={!message.trim()}
                className={styles.sendButton}
                aria-label="Send message"
              >
                Send
              </button>
              <button
                onClick={handleCloseModal}
                className={styles.cancelButton}
                aria-label="Cancel sending message"
              >
                Cancel
              </button>
            </div>
            <div
              className={`${styles.charCount} ${
                1000 - message.length <= 100 ? styles.warning : ''
              }`}
              aria-live="polite"
            >
              {1000 - message.length} characters remaining
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AcceptedConnections;
