// src/components/ProtectedLayout.js

import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from './TopBar'; // Ensure the path is correct
import PropTypes from 'prop-types'; // Import PropTypes for type checking

function ProtectedLayout({
  onEditProfile,
  onInbox,
  onConnections,
  onConnectionRequest,
  onSearchUsers,
}) {
  // State to manage connection status
  const [connectionStatus, setConnectionStatus] = useState('connected');

  useEffect(() => {
    // Handler for online event
    const handleOnline = () => {
      setConnectionStatus('connected');
    };

    // Handler for offline event
    const handleOffline = () => {
      setConnectionStatus('disconnected');
    };

    // Add event listeners
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Initial status check
    if (!navigator.onLine) {
      setConnectionStatus('disconnected');
    }

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      <TopBar
        onSearchUsers={onSearchUsers}
        connectionStatus={connectionStatus}
      />
      <main>
        <Outlet />
      </main>
    </>
  );
}

ProtectedLayout.propTypes = {
  onEditProfile: PropTypes.func.isRequired,
  onInbox: PropTypes.func.isRequired,
  onConnections: PropTypes.func.isRequired,
  onConnectionRequest: PropTypes.func.isRequired,
  onSearchUsers: PropTypes.func.isRequired,
};

export default ProtectedLayout;
