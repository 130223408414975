import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiFetch } from '../utils/api';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [token, setToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the token from the URL query parameter
    const queryParams = new URLSearchParams(window.location.search);
    const resetToken = queryParams.get('token');
    if (!resetToken) {
      setResponseMessage('Invalid or missing token. Please check your link.');
    } else {
      setToken(resetToken);
    }
  }, []);

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation before hitting the API
    if (!newPassword) {
      setResponseMessage('Please enter a new password.');
      return;
    }

    setIsSubmitting(true);
    setResponseMessage('');

    try {
      const result = await apiFetch('password-reset/confirm', {
        method: 'POST',
        body: {
          token: token,
          newPassword: newPassword,
        },
      });

      // If successful, display success and redirect or allow user to proceed
      if (result.message) {
        toast.success(result.message);
        setResponseMessage(result.message);
        // Optionally redirect to login:
        // navigate('/login');
      } else if (result.error) {
        setResponseMessage(result.error);
        toast.error(result.error);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      const errorMsg = error.message || 'An error occurred. Please try again.';
      setResponseMessage(errorMsg);
      toast.error(errorMsg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="reset-password-page">
      <form onSubmit={handleSubmit} className="reset-password-form" aria-label="Reset Password Form">
        <h2>Reset Your Password</h2>
        
        {/* If the token is missing or invalid, show a message and disable form */}
        {(!token || responseMessage.toLowerCase().includes('invalid')) && (
          <p role="alert" className="response error">{responseMessage || 'Invalid token.'}</p>
        )}

        {token && (
          <>
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={handlePasswordChange}
              required
              placeholder="Enter your new password"
              aria-required="true"
            />

            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting || !newPassword}
              aria-disabled={isSubmitting || !newPassword}
            >
              {isSubmitting ? 'Resetting...' : 'Reset Password'}
            </button>
          </>
        )}

        {responseMessage && !responseMessage.toLowerCase().includes('invalid') && (
          <p
            className={`response ${responseMessage.toLowerCase().includes('error') ? 'error' : 'success'}`}
            role="alert"
          >
            {responseMessage}
          </p>
        )}
      </form>
    </div>
  );
}

export default ResetPassword;
