// src/components/TopBar.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './TopBar.css';
import logo from '../assets/newghost.webp';
import useHideOnScroll from './useHideOnScroll';
import ConnectionStatus from './ConnectionStatus';
import PropTypes from 'prop-types';
import { FaBell } from 'react-icons/fa';
import { WebSocketContext } from '../context/WebSocketContext';
import { AuthContext } from '../context/AuthContext';
import { ThemeContext } from '../context/ThemeContext'; // Import ThemeContext

function TopBar({ onSearchUsers, connectionStatus }) {
  useHideOnScroll(); // Custom hook to hide on scroll
  const navigate = useNavigate();

  // Get user from AuthContext
  const { user, logout } = useContext(AuthContext);

  // Use the WebSocket context
  const {
    notifications,
    setNotifications,
    // Any other shared state or functions
  } = useContext(WebSocketContext);

  // Consume ThemeContext directly
  const { theme, toggleTheme } = useContext(ThemeContext);

  // State to manage the visibility of the mobile menu
  const [menuActive, setMenuActive] = useState(false);

  // State to manage the search input
  const [searchQuery, setSearchQuery] = useState('');

  // State to manage the visibility of the notifications popup
  const [notificationsActive, setNotificationsActive] = useState(false);

  // Refs for handling outside clicks
  const topButtonsRef = useRef(null);
  const notificationsRef = useRef(null);

  // Handler to toggle the mobile menu
  const toggleMenu = () => {
    setMenuActive((prevState) => !prevState);
  };

  // Handler to toggle the notifications popup
  const toggleNotifications = () => {
    setNotificationsActive((prevState) => !prevState);
  };

  // Close the notifications popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target) &&
        !event.target.closest('.notification-icon')
      ) {
        setNotificationsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Close the menu when a button is clicked
  const handleButtonClick = (callback) => {
    callback();
    setMenuActive(false);
  };

  // Handler for search input changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handler for search form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (onSearchUsers && searchQuery.trim() !== '') {
      onSearchUsers(searchQuery.trim());
      setSearchQuery('');
      if (menuActive) setMenuActive(false); // Close menu after search
    }
  };

  // Determine the theme toggle icon based on the current theme
  const themeToggleIcon = theme === 'light' ? '🌙' : '☀️';

  // Calculate the number of unread notifications
  const unreadCount = notifications.filter((notification) => !notification.read).length;

  // Handler to mark a single notification as read
  const handleMarkAsRead = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification
      )
    );
  };

  // Handler to mark all notifications as read
  const handleMarkAllAsRead = () => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => ({ ...notification, read: true }))
    );
  };

  // Handlers for navigation
  const handleEditProfile = () => {
    navigate('/user-profile');
  };

  const handleInbox = () => {
    navigate('/inbox');
  };

  const handleConnections = () => {
    navigate('/accepted-connections');
  };

  const handleConnectionRequests = () => {
    navigate('/connection-requests');
  };

  const handleLogout = () => {
    logout(); // Call the logout function from AuthContext
  };

  return (
    <header className={`top-bar ${menuActive ? 'menu-active' : ''}`}>
      {/* Left Section: Logo and Connection Status */}
      <div className="top-bar-left">
        {/* Logo Section wrapped with Link */}
        <div className="logo">
          <Link to="/dashboard">
            <img src={logo} alt="MyApp Logo" />
          </Link>
        </div>

        {/* Connection Status */}
        <ConnectionStatus status={connectionStatus} />
      </div>

      {/* Right Section: Theme Toggle, Search, Notifications, Avatar, and Hamburger Menu */}
      <div className="top-bar-right">
        {/* Theme Toggle Button (Visible on Desktop) */}
        <button
          className={`theme-toggle ${menuActive ? 'mobile-hidden' : ''}`}
          onClick={toggleTheme}
          aria-label="Toggle theme"
        >
          {themeToggleIcon}
        </button>

        {/* Search Bar (Visible on Desktop) */}
        <form
          className={`search-form ${menuActive ? 'mobile-hidden' : ''}`}
          onSubmit={handleSearchSubmit}
        >
          <input
            type="text"
            className="search-input"
            placeholder="Search users..."
            value={searchQuery}
            onChange={handleSearchChange}
            aria-label="Search users"
          />
          <button type="submit" className="search-button" aria-label="Search">
            🔍
          </button>
        </form>

        {/* Notification Icon */}
        <div
          className="notification-icon"
          onClick={toggleNotifications}
          aria-label="View notifications"
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter') toggleNotifications();
          }}
        >
          <FaBell />
          {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
        </div>

        {/* User Avatar */}
        {user && (
          <div className="user-avatar">
            <img
              src={user.avatarUrl || '/default-avatar.png'}
              alt={`${user.firstName} ${user.lastName}'s avatar`}
              className="avatar-image"
              onClick={() => navigate('/user-profile')}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}

        {/* Hamburger Menu Button */}
        <button
          className="hamburger-menu"
          onClick={toggleMenu}
          aria-label="Toggle navigation menu"
          aria-expanded={menuActive}
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter') toggleMenu();
          }}
        >
          ☰
        </button>

        {/* Navigation Buttons (Dropdown Menu) */}
        <nav
          ref={topButtonsRef}
          className={`top-buttons ${menuActive ? 'active' : ''}`}
          aria-label="Primary Navigation"
        >
          {/* Search Bar for Mobile Menu */}
          <form className="search-form mobile-search" onSubmit={handleSearchSubmit}>
            <input
              type="text"
              className="search-input"
              placeholder="Search users..."
              value={searchQuery}
              onChange={handleSearchChange}
              aria-label="Search users"
            />
            <button type="submit" className="search-button" aria-label="Search">
              🔍
            </button>
          </form>

          <button
            className="button gray-button edit-profile-button"
            onClick={() => handleButtonClick(handleEditProfile)}
            aria-label="Edit Profile"
          >
            Edit Profile
          </button>
          <button
            className="button gray-button inbox-button"
            onClick={() => handleButtonClick(handleInbox)}
            aria-label="Inbox"
          >
            Inbox
          </button>
          <button
            className="button gray-button connections-button"
            onClick={() => handleButtonClick(handleConnections)}
            aria-label="Connections"
          >
            Connections
          </button>
          <button
            className="button gray-button connection-request-button"
            onClick={() => handleButtonClick(handleConnectionRequests)}
            aria-label="Connection Requests"
          >
            Connection Requests
          </button>
          <button
            className="button logout-button"
            onClick={() => handleButtonClick(handleLogout)}
            aria-label="Logout"
          >
            Logout
          </button>
        </nav>

        {/* Notifications Popup */}
        {notificationsActive && (
          <div className="notifications-popup" ref={notificationsRef}>
            <div
              className="popup-overlay"
              onClick={() => setNotificationsActive(false)}
              aria-hidden="true"
            ></div>
            <div
              className="popup-content"
              role="dialog"
              aria-modal="true"
              aria-labelledby="notifications-title"
            >
              <button
                className="close-popup"
                onClick={() => setNotificationsActive(false)}
                aria-label="Close notifications"
              >
                &times;
              </button>
              <h2 id="notifications-title">Notifications</h2>
              <ul className="notifications-list">
                {notifications.length === 0 && <li>No notifications</li>}
                {notifications.map((notification) => (
                  <li key={notification.id} className={`notification-item ${notification.read ? 'read' : 'unread'}`}>
                    <p>{notification.message}</p>
                    <span className="notification-timestamp">{new Date(notification.timestamp).toLocaleString()}</span>
                    {!notification.read && (
                      <button
                        onClick={() => handleMarkAsRead(notification.id)}
                        aria-label="Mark notification as read"
                        className="mark-read-button"
                      >
                        Mark as Read
                      </button>
                    )}
                  </li>
                ))}
              </ul>
              {notifications.length > 0 && (
                <button
                  className="mark-all-read-button"
                  onClick={handleMarkAllAsRead}
                  aria-label="Mark all notifications as read"
                >
                  Mark All as Read
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

TopBar.propTypes = {
  onSearchUsers: PropTypes.func.isRequired,
  connectionStatus: PropTypes.oneOf(['connected', 'pending', 'disconnected']).isRequired,
  // Removed onToggleTheme and currentTheme since they are now handled via ThemeContext
};

export default TopBar;
