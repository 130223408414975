// src/components/Profile.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiFetch } from '../utils/api'; // Import apiFetch
import './Profile.css'; // Import custom styles
import { toast, ToastContainer } from 'react-toastify'; // Import Toastify for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles

function Profile() {
  const { id } = useParams(); // Get user ID from URL
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(''); // Error state
  const [isNavigating, setIsNavigating] = useState(false); // Navigation state to prevent multiple clicks

  useEffect(() => {
    const fetchProfile = async () => {
      if (!id) {
        setError('No user ID provided.');
        setLoading(false);
        return;
      }

      setLoading(true);
      setError('');

      try {
        // Make the API call using apiFetch
        const data = await apiFetch(`users/${id}`);
        setProfileData(data); // Assuming backend sends data directly
      } catch (err) {
        console.error('Error fetching profile data:', err);
        if (err.status === 401) {
          // If unauthorized, redirect to login
          navigate('/login');
        } else if (err.status === 404) {
          setError('User not found.');
        } else {
          setError(err.message || 'Failed to fetch profile data.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [id, navigate]);

  const handleViewFeed = async () => {
    if (isNavigating) return; // Prevent multiple navigations
    setIsNavigating(true);
    navigate(`/shared-feed/${id}`);
  };

  if (loading) {
    return (
      <div className="profile-page">
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Loading profile...</p>
        </div>
        <ToastContainer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="profile-page">
        <div className="error-container">
          <p className="error-message">{error}</p>
          <button onClick={() => navigate('/inbox')} className="btn btn-secondary">
            Back to Inbox
          </button>
        </div>
        <ToastContainer />
      </div>
    );
  }

  if (!profileData) {
    return (
      <div className="profile-page">
        <p>No profile data available.</p>
        <button onClick={() => navigate('/inbox')} className="btn btn-secondary">
          Back to Inbox
        </button>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="profile-page">
      <div className="profile-container">
        <div className="profile-header">
          {profileData.avatarUrl && (
            <img
              src={profileData.avatarUrl}
              alt={`${profileData.name}'s avatar`}
              className="avatar-image"
            />
          )}
          <h2 className="profile-name">{profileData.name}</h2>
        </div>
        <div className="profile-details">
          <p>
            <strong>Email:</strong> {profileData.email}
          </p>
          {profileData.firstName && (
            <p>
              <strong>First Name:</strong> {profileData.firstName}
            </p>
          )}
          {profileData.lastName && (
            <p>
              <strong>Last Name:</strong> {profileData.lastName}
            </p>
          )}
          {profileData.bio && (
            <p>
              <strong>Bio:</strong> {profileData.bio}
            </p>
          )}
          {/* Add more profile fields as necessary */}
        </div>
        <div className="profile-actions">
          <button
            className="view-feed-button btn btn-primary"
            onClick={handleViewFeed}
            disabled={isNavigating}
            aria-disabled={isNavigating}
            aria-label="View Shared Feed"
          >
            {isNavigating ? 'Navigating...' : 'View Feed'}
          </button>
          <button
            className="edit-profile-button btn btn-secondary"
            onClick={() => navigate(`/edit-profile/${id}`)}
            aria-label="Edit Profile"
          >
            Edit Profile
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Profile;
