// src/components/About.js
import React, { useEffect } from 'react';
import './About.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

function About() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true, // Ensures animation happens only once
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>About GhostCache | Revolutionizing Human Connections</title>
        <meta
          name="description"
          content="Discover how GhostCache is transforming the way people connect through innovative technology and unique networking solutions. Join us in building meaningful relationships and expanding your network."
        />
        <meta
          name="keywords"
          content="GhostCache, Innovative Networking, Connect People, Social Platform, Human Connections, Networking Solutions, Modern Networking, Community Building, Relationship Building, Digital Connections"
        />
        <meta property="og:title" content="About GhostCache | Revolutionizing Human Connections" />
        <meta
          property="og:description"
          content="GhostCache is at the forefront of transforming human connections with innovative technology and unique networking solutions. Learn more about our mission and values."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ghostcache.com/about" />
        <meta property="og:image" content="https://www.ghostcache.com/images/og-image.jpg" />
      </Helmet>
      <main className="about-container">
        <section className="mission-section" data-aos="fade-up">
          <h1>Our Mission at GhostCache</h1>
          <p>
            At <strong>GhostCache</strong>, our mission is to redefine human connections by leveraging cutting-edge technology and innovative networking solutions. We strive to create a platform where individuals can build meaningful relationships, expand their networks, and collaborate seamlessly in both personal and professional realms.
          </p>
        </section>

        <section className="values-section" data-aos="fade-up" data-aos-delay="200">
          <h2>Our Core Values</h2>
          <ul>
            <li>
              <span className="icon">✨</span>
              <strong>Innovation:</strong> We continuously push the boundaries of technology to create unique and effective ways for people to connect.
            </li>
            <li>
              <span className="icon">🤝</span>
              <strong>Integrity:</strong> We maintain the highest ethical standards, ensuring trust and transparency in all our interactions.
            </li>
            <li>
              <span className="icon">🌐</span>
              <strong>Inclusivity:</strong> Our platform is designed to be accessible and welcoming to individuals from all walks of life.
            </li>
            <li>
              <span className="icon">💡</span>
              <strong>Creativity:</strong> We foster a creative environment that encourages new ideas and innovative solutions.
            </li>
            <li>
              <span className="icon">🚀</span>
              <strong>Excellence:</strong> We are committed to delivering the best user experience through continuous improvement and dedication.
            </li>
          </ul>
        </section>

        <section className="cta-section" data-aos="fade-up" data-aos-delay="400">
          <h2>Join Us in Shaping the Future of Connections</h2>
          <p>
            Whether you're looking to expand your personal network, collaborate on projects, or simply meet like-minded individuals, GhostCache is your go-to platform for building meaningful and lasting relationships. Let's innovate together.
          </p>
          <button className="cta-button" onClick={() => window.location.href='/signup'}>
            Get Started
          </button>
        </section>
      </main>
    </>
  );
}

export default About;
