// src/components/CommentSection.js

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './CommentSection.css';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { apiFetch } from '../utils/api'; // Import the apiFetch utility

function CommentSection({ feedItemId, visibleCommentsCount, setVisibleCommentsCount }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [error, setError] = useState(null);

  // Fetch comments for the given feedItemId using apiFetch
  const fetchComments = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Use the relative endpoint without the base URL
      const data = await apiFetch(`shared-feed/${feedItemId}/comments`, {
        method: 'GET',
      });

      if (Array.isArray(data)) {
        setComments(data);
      } else {
        console.warn('Unexpected data format for comments:', data);
        setComments([]);
      }
    } catch (err) {
      console.error('Error fetching comments:', err);
      setError(err.message);
      setComments([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle adding a new comment using apiFetch
  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    setIsPosting(true);
    setError(null);

    const postPayload = {
      content: newComment.trim(),
    };

    try {
      // Use the relative endpoint without the base URL
      const data = await apiFetch(`shared-feed/${feedItemId}/comment`, {
        method: 'POST',
        body: postPayload, // apiFetch will stringify the body
      });

      if (data.comment) {
        setComments((prevComments) => [...prevComments, data.comment]);
        setNewComment('');
        setVisibleCommentsCount((prevCount) => prevCount + 1); // Automatically show the new comment
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (err) {
      console.error('Error posting comment:', err);
      setError(err.message);
    } finally {
      setIsPosting(false);
    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  // Fetch comments on mount and when feedItemId changes
  useEffect(() => {
    if (feedItemId) {
      fetchComments();
    }
  }, [feedItemId]);

  // Function to handle loading more comments
  const handleLoadMoreComments = () => {
    setVisibleCommentsCount((prevCount) => prevCount + 3);
  };

  // Function to render comments up to the visibleCommentsCount
  const renderComments = () => {
    return comments.slice(0, visibleCommentsCount).map((comment) => (
      <motion.li
        key={comment.id}
        className="comment"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="comment-header">
          <span className="comment-email">{comment.userEmail}</span>
          <span className="comment-time">
            {formatDistanceToNow(parseISO(comment.timestamp), { addSuffix: true })}
          </span>
        </div>
        <p className="comment-content">{comment.content}</p>
      </motion.li>
    ));
  };

  // Determine if there are more comments to load
  const hasMoreComments = visibleCommentsCount < comments.length;

  return (
    <div className="comment-section">
      <h4>Comments</h4>

      {/* Loading Indicator */}
      {isLoading && <p>Loading comments...</p>}

      {/* Error Message */}
      {error && <p className="error-message">Error: {error}</p>}

      {/* Comments List */}
      {!isLoading && !error && (
        <>
          <div className="comments-container">
            <ul className="comments-list">
              <AnimatePresence>
                {comments.length === 0 ? (
                  <motion.li
                    key="no-comments"
                    className="no-comments"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    No comments yet.
                  </motion.li>
                ) : (
                  renderComments()
                )}
              </AnimatePresence>
            </ul>
          </div>

          {/* Load More Comments Button */}
          {hasMoreComments && (
            <button
              className="load-more-comments"
              onClick={handleLoadMoreComments}
              aria-label="Load more comments"
            >
              Load More Comments
            </button>
          )}
        </>
      )}

      {/* Add Comment Form */}
      <form className="add-comment-form" onSubmit={handleAddComment}>
        <input
          type="text"
          placeholder="Add a comment..."
          value={newComment}
          onChange={handleInputChange}
          aria-label="Add a comment"
          required
          disabled={isPosting}
        />
        <button type="submit" disabled={isPosting || !newComment.trim()} aria-label="Post comment">
          {isPosting ? 'Posting...' : 'Post'}
        </button>
      </form>
    </div>
  );
}

CommentSection.propTypes = {
  feedItemId: PropTypes.number.isRequired,
  visibleCommentsCount: PropTypes.number.isRequired,
  setVisibleCommentsCount: PropTypes.func.isRequired,
};

export default CommentSection;
