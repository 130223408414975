// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client'; // For React 18+
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext'; // Import ThemeProvider
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import { WebSocketProvider } from './context/WebSocketContext'; // Import WebSocketProvider
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider> {/* Provides AuthContext */}
        <WebSocketProvider> {/* Needs AuthContext */}
          <ThemeProvider> {/* Provides ThemeContext */}
            <App />
          </ThemeProvider>
        </WebSocketProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
