// src/utils/imageCompression.js

import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';

/**
 * Converts HEIC/HEIF image to JPEG using heic2any.
 *
 * @param {File} imageFile - The HEIC/HEIF image file to convert.
 * @returns {Promise<File>} - The converted JPEG image file.
 */
export const convertHeicToJpeg = async (imageFile) => {
  try {
    const convertedBlob = await heic2any({
      blob: imageFile,
      toType: 'image/jpeg',
      quality: 0.8, // Adjust quality as needed (0 to 1)
    });

    // Create a new File object from the converted Blob
    const convertedFile = new File([convertedBlob], imageFile.name.replace(/\.(heic|heif)$/i, '.jpeg'), {
      type: 'image/jpeg',
      lastModified: Date.now(),
    });

    return convertedFile;
  } catch (error) {
    console.error('Error converting HEIC to JPEG:', error);
    throw error;
  }
};

/**
 * Compresses an image file using browser-image-compression.
 * Converts HEIC/HEIF to JPEG before compression.
 *
 * @param {File} imageFile - The original image file to compress.
 * @param {Object} [options] - Optional compression options.
 * @param {number} [options.maxSizeMB=1] - Maximum size in MB.
 * @param {number} [options.maxWidthOrHeight=1920] - Maximum width or height in pixels.
 * @param {number} [options.initialQuality=0.8] - Initial quality value.
 * @param {boolean} [options.useWebWorker=true] - Whether to use Web Workers for compression.
 * @returns {Promise<File>} - The compressed image file.
 */
export const compressImage = async (imageFile, options = {}) => {
  const defaultOptions = {
    maxSizeMB: 1, // Maximum size in MB
    maxWidthOrHeight: 1920, // Max width or height in pixels
    useWebWorker: true, // Use multi-threading
    initialQuality: 0.8, // Initial quality value
  };

  const compressionOptions = { ...defaultOptions, ...options };

  try {
    let fileToCompress = imageFile;

    // Check if the file is HEIC/HEIF based on its extension
    const heicExtensions = ['heic', 'heif'];
    const fileExtension = imageFile.name.split('.').pop().toLowerCase();

    if (heicExtensions.includes(fileExtension)) {
      console.log('Converting HEIC/HEIF image to JPEG...');
      fileToCompress = await convertHeicToJpeg(imageFile);
    }

    // Compress the (converted) image
    const compressedFile = await imageCompression(fileToCompress, compressionOptions);
    return compressedFile;
  } catch (error) {
    console.error('Error during image compression:', error);
    throw error;
  }
};
