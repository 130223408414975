import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import './FeedItem.css';
import CommentSection from './CommentSection';
import { FaHeart, FaRegHeart, FaComment, FaShare, FaTrash } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import ImageWithFallback from './ImageWithFallback';
import { AuthContext } from '../context/AuthContext';

function FeedItem({ item, toggleLike, handleToggleComments, visibleComments, onDelete }) {
  const { user } = useContext(AuthContext);

  const {
    id,
    authorId,
    authorName,
    authorAvatarUrl,
    content,
    imageUrl,
    timestamp,
    likesCount,
    commentsCount,
    sharesCount,
    liked,
  } = item;

  const formattedTime = new Date(timestamp).toLocaleString();

  const [visibleCommentsCount, setVisibleCommentsCount] = useState(3);
  const [showBurst, setShowBurst] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  const isAuthor = authorId === user?.id;

  const displayAvatarUrl = isAuthor && user?.avatarUrl ? user.avatarUrl : authorAvatarUrl;
  const displayAuthorName = isAuthor && user?.firstName && user?.lastName
    ? `${user.firstName} ${user.lastName}`
    : authorName;

  const handleLikeClick = () => {
    toggleLike(id, liked);
    if (!liked) {
      setShowBurst(true);
      setTimeout(() => setShowBurst(false), 1000);
    }
  };

  const handleDeleteClick = async () => {
    setIsDeleting(true);
    try {
      await onDelete(id);
      setIsDeleting(false);
    } catch (error) {
      console.error('Delete operation failed:', error);
      setDeleteError('Failed to delete the post.');
      setIsDeleting(false);
    }
  };

  return (
    <motion.div
      className="feed-item card"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Feed Item Header */}
      <div className="feed-item-header">
        <ImageWithFallback
          src={displayAvatarUrl}
          fallbackSrc="/default-avatar.png"
          alt={`${displayAuthorName}'s avatar`}
          className="avatar"
        />
        <div className="author-info">
          <h4>{displayAuthorName}</h4>
          <p>{formattedTime}</p>
        </div>
      </div>

      {/* Feed Item Content */}
      <div className="feed-item-content">
        <p>{content}</p>
        {imageUrl && (
          <ImageWithFallback
            src={imageUrl}
            fallbackSrc="/helmetrobo.jpg"
            alt="Post visual content"
            className="post-image"
            loading="lazy"
          />
        )}
      </div>

      {/* Feed Item Actions */}
      <div className="feed-item-actions">
        {/* Like Button */}
        <motion.button
          className={`action-button like-button ${liked ? 'liked' : ''}`}
          onClick={handleLikeClick}
          aria-label={liked ? 'Unlike this post' : 'Like this post'}
          data-tooltip={liked ? 'Unlike' : 'Like'}
          whileTap={{ scale: 0.9 }}
          initial={false}
          animate={{
            scale: liked ? [1, 1.3, 1] : 1,
            color: liked ? '#e0245e' : 'var(--color-text-secondary)',
          }}
          transition={{ type: 'spring', stiffness: 300, damping: 10 }}
        >
          {liked ? <FaHeart /> : <FaRegHeart />} <span>{likesCount}</span>
        </motion.button>

        {/* Burst Animation */}
        <AnimatePresence>
          {showBurst && (
            <motion.div
              className="burst-container"
              initial={{ opacity: 1, scale: 0 }}
              animate={{ opacity: 0, scale: 2 }}
              exit={{ opacity: 0, scale: 2 }}
              transition={{ duration: 1 }}
            >
              <FaHeart className="burst-heart" />
              <FaHeart className="burst-heart" />
              <FaHeart className="burst-heart" />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Comment Button */}
        <motion.button
          className="action-button comment-button"
          onClick={() => handleToggleComments(id)}
          aria-label="Toggle comments"
          data-tooltip="Comment"
          whileTap={{ scale: 0.9 }}
        >
          <FaComment /> <span>{commentsCount}</span>
        </motion.button>

        {/* Share Button */}
        <motion.button
          className="action-button share-button"
          onClick={() => {
            if (navigator.share) {
              navigator
                .share({
                  title: 'Check out this post!',
                  text: content,
                  url: window.location.href,
                })
                .catch((error) => console.error('Error sharing:', error));
            } else {
              alert('Share functionality is not supported in your browser.');
            }
          }}
          aria-label="Share this post"
          data-tooltip="Share"
          whileTap={{ scale: 0.9 }}
        >
          <FaShare /> <span>{sharesCount}</span>
        </motion.button>

        {/* Delete Button - Only visible to the post author */}
        {isAuthor && (
          <motion.button
            className="action-button delete-button"
            onClick={handleDeleteClick}
            aria-label="Delete this post"
            data-tooltip="Delete"
            whileTap={{ scale: 0.9 }}
            disabled={isDeleting}
          >
            {isDeleting ? 'Deleting...' : <FaTrash />}
          </motion.button>
        )}
      </div>

      {/* Display deletion error if any */}
      {deleteError && <p className="error-message">{deleteError}</p>}

      {/* Comment Section */}
      {visibleComments[id] && (
        <CommentSection
          feedItemId={id}
          visibleCommentsCount={visibleCommentsCount}
          setVisibleCommentsCount={setVisibleCommentsCount}
        />
      )}
    </motion.div>
  );
}

FeedItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    authorId: PropTypes.number.isRequired,
    authorName: PropTypes.string.isRequired,
    authorAvatarUrl: PropTypes.string,
    content: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    timestamp: PropTypes.string.isRequired,
    likesCount: PropTypes.number.isRequired,
    commentsCount: PropTypes.number.isRequired,
    sharesCount: PropTypes.number.isRequired,
    liked: PropTypes.bool.isRequired,
  }).isRequired,
  toggleLike: PropTypes.func.isRequired,
  handleToggleComments: PropTypes.func.isRequired,
  visibleComments: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default FeedItem;
