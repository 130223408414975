// src/components/MessageDetail.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiFetch } from '../utils/api'; // Import apiFetch
import './MessageDetail.css'; // Import custom styles

function MessageDetail() {
  const { messageId } = useParams();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [error, setError] = useState(''); // Error state
  const [isDeleting, setIsDeleting] = useState(false); // Deleting state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMessage = async () => {
      setIsLoading(true);
      setError('');
      try {
        const data = await apiFetch(`messages/${messageId}`);
        setMessage(data.message);
      } catch (err) {
        console.error('Error fetching message:', err);
        if (err.status === 401) {
          // If unauthorized, redirect to login
          navigate('/login');
        } else {
          setError(err.message || 'Failed to fetch message details.');
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchMessage();
  }, [messageId, navigate]);

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this message?');
    if (!confirmDelete) return;

    setIsDeleting(true);
    setError('');

    try {
      await apiFetch(`messages/${messageId}`, {
        method: 'DELETE',
      });
      // After successful deletion, navigate back to inbox
      navigate('/inbox');
    } catch (err) {
      console.error('Error deleting message:', err);
      if (err.status === 401) {
        // If unauthorized, redirect to login
        navigate('/login');
      } else {
        setError(err.message || 'Failed to delete the message.');
      }
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="message-detail loading">
        <div className="spinner"></div>
        <p>Loading message...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="message-detail error">
        <p>{error}</p>
        <button onClick={() => navigate('/inbox')} className="btn btn-secondary">
          Back to Inbox
        </button>
      </div>
    );
  }

  if (!message) {
    return (
      <div className="message-detail no-message">
        <p>Message not found.</p>
        <button onClick={() => navigate('/inbox')} className="btn btn-secondary">
          Back to Inbox
        </button>
      </div>
    );
  }

  return (
    <div className="message-detail">
      <h2>Message Detail</h2>
      <p>
        <strong>From:</strong> {message.senderEmail}
      </p>
      <p>
        <strong>To:</strong> {message.recipientEmail}
      </p>
      <p>
        <strong>Content:</strong> {message.content}
      </p>
      <p>
        <strong>Date:</strong> {new Date(message.timestamp).toLocaleString()}
      </p>
      <div className="actions">
        <button onClick={handleDelete} className="btn btn-danger" disabled={isDeleting}>
          {isDeleting ? 'Deleting...' : 'Delete'}
        </button>
        <button onClick={() => navigate('/inbox')} className="btn btn-secondary">
          Back to Inbox
        </button>
      </div>
    </div>
  );
}

export default MessageDetail;
