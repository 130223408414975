// src/components/LandingPage.js

import React, { useState, useContext, useEffect, useRef, useCallback, useMemo } from 'react';
import Modal from 'react-modal';
import './LandingPage.css';
import { AuthContext } from '../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import FeatureCard from '../components/FeatureCard';
import collaborationImage from '../assets/collaboration.jpg';
import analyticsImage from '../assets/analytics.jpg';
import themesImage from '../assets/themes.jpg';
import ghcacheImage from '../assets/newghost.webp'; // Import ghcache.webp
import AOS from 'aos';
import 'aos/dist/aos.css';

import { apiFetch } from '../utils/api'; // Import apiFetch utility

// Set the app element for accessibility
Modal.setAppElement('#root');

function LandingPage() {
  // State for modals
  const [showLogin, setShowLogin] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [showContact, setShowContact] = useState(false); // New state for Contact Modal
  const [showResendVerification, setShowResendVerification] = useState(false);
  const [resendEmail, setResendEmail] = useState('');

  // State for Create Account form
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  // State for Login form
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  // State for Contact form
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  // Response messages
  const [createResponseMessage, setCreateResponseMessage] = useState('');
  const [loginResponseMessage, setLoginResponseMessage] = useState('');
  const [contactResponseMessage, setContactResponseMessage] = useState(''); // New state

  // Submission states
  const [isSubmittingCreate, setIsSubmittingCreate] = useState(false);
  const [isSubmittingLogin, setIsSubmittingLogin] = useState(false);
  const [isSubmittingContact, setIsSubmittingContact] = useState(false); // New state

  const featuresData = [
    {
      id: 'collaboration',
      title: 'Real-time Collaboration',
      description: 'Work together with your team in real-time, no matter where you are.',
      image: collaborationImage,
      link: '/features/collaboration',
      delay: 'delay-1',
    },
    {
      id: 'analytics',
      title: 'Advanced Analytics',
      description: 'Gain insights with our new analytics dashboard to make informed decisions.',
      image: analyticsImage,
      link: '/features/analytics',
      delay: 'delay-2',
    },
    {
      id: 'themes',
      title: 'Customizable Themes',
      description: 'Personalize your experience with our new range of customizable themes.',
      image: themesImage,
      link: '/features/themes',
      delay: 'delay-3',
    },
  ];

  // Initialize navigate
  const navigate = useNavigate();

  // Access AuthContext
  const { login, signup, resendVerificationEmail } = useContext(AuthContext); // Include resendVerificationEmail

  // Initialize AOS animations
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  // Define apiUrl early to use in handlers
  const apiUrl = process.env.REACT_APP_API_URL;

  // Handle input changes for Create Account form
  const handleCreateInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  // Handle input changes for Login form
  const handleLoginInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  // Handle input changes for Contact form
  const handleContactInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setContactData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  // Handle Create Account form submission
  const handleCreateAccount = useCallback(async (e) => {
    e.preventDefault();
    setCreateResponseMessage('');
    setIsSubmittingCreate(true);

    try {
      const result = await signup(formData);
      if (result.success) {
        setShowCreateAccount(false);
        toast.success(
          result.message ||
            'Account created successfully! Please check your email to verify your account.'
        );
      } else {
        setCreateResponseMessage(result.error || 'Failed to create account.');
        toast.error(result.error || 'Failed to create account.');
      }
    } catch (error) {
      console.error('Error during account creation:', error);
      setCreateResponseMessage('An error occurred. Please try again.');
      toast.error('An error occurred. Please try again.');
    } finally {
      setIsSubmittingCreate(false);
    }
  }, [signup, formData]);

  // Handle Login form submission
  const handleLogin = useCallback(async (e) => {
    e.preventDefault();
    setLoginResponseMessage('');
    setIsSubmittingLogin(true);
    setShowResendVerification(false);

    try {
      const result = await login(loginData);
      if (result.success) {
        setShowLogin(false);
        toast.success('Logged in successfully!');
        navigate('/dashboard'); // Redirect to dashboard
      } else {
        const errorMessage = result.error || 'Failed to login.';
        setLoginResponseMessage(errorMessage);
        toast.error(errorMessage);

        // Check if the error is due to unverified email
        if (result.error === 'unverified_email') { // Updated condition
          setShowResendVerification(true);
          setResendEmail(loginData.email);
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
      setLoginResponseMessage('An error occurred. Please try again.');
      toast.error('An error occurred. Please try again.');
    } finally {
      setIsSubmittingLogin(false);
    }
  }, [login, loginData, navigate]);

  // Handle Resend Verification Email
  const handleResendVerification = useCallback(async () => {
    if (!resendEmail) {
      toast.error('No email available to resend verification.');
      return;
    }

    try {
      const result = await resendVerificationEmail(resendEmail);
      if (result.success) {
        toast.success(result.message || 'Verification email sent successfully.');
        setShowResendVerification(false); // Hide the resend section
      } else {
        toast.error(result.error || 'Failed to resend verification email.');
      }
    } catch (error) {
      console.error('Error resending verification email:', error);
      toast.error('An error occurred while trying to resend the verification email.');
    }
  }, [resendEmail, resendVerificationEmail]);

  // Handle Contact form submission
  const isMounted = useRef(true); // useRef imported and used here
  const contactController = useRef(null); // useRef imported and used here

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      if (contactController.current) {
        contactController.current.abort();
      }
    };
  }, []);

  const handleContactSubmit = useCallback(async (e) => {
    e.preventDefault();
    setContactResponseMessage('');
    setIsSubmittingContact(true);

    try {
      const data = await apiFetch('submit_contact', {
        method: 'POST',
        credentials: 'include', // Include cookies if needed
        body: contactData,
      });

      setContactResponseMessage(data.message || 'Your message has been sent successfully!');
      toast.success(data.message || 'Your message has been sent successfully!');
      setShowContact(false);
      setContactData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      if (error.name === 'TypeError') {
        console.error('Expected JSON, but received:', error.message);
        setContactResponseMessage('Unexpected server response. Please try again later.');
        toast.error('Unexpected server response. Please try again later.');
      } else {
        console.error('Error submitting contact form:', error);
        setContactResponseMessage(error.message || 'An error occurred. Please try again.');
        toast.error(error.message || 'An error occurred. Please try again.');
      }
    } finally {
      if (isMounted.current) {
        setIsSubmittingContact(false);
      }
    }
  }, [contactData]);

  // Memoize featuresData if necessary
  const featuresList = useMemo(() => featuresData, [featuresData]);

  return (
    <div className="landing-page">
      <ToastContainer />
      <header>
        <nav>
          <a href="#home">Home</a>
          <a href="#features">Features</a>
          <Link to="/about">About</Link>
          <button
            className="nav-button"
            onClick={() => setShowContact(true)}
            style={{
              background: 'none',
              border: 'none',
              color: '#3498DB',
              cursor: 'pointer',
              padding: '14px 20px',
              fontSize: '1em',
            }}
            aria-label="Open Contact Modal"
          >
            Contact
          </button>
        </nav>
      </header>

      <section id="home" className="home-container" data-aos="fade-in">
        {/* Replace the text with the ghcache.webp image */}
        <h1 className="welcome-message" data-aos="fade-up">
          <img src={ghcacheImage} alt="ghcache logo" className="ghcache-logo" />
        </h1>
        <button
          className="btn btn-primary animated-button"
          onClick={() => setShowCreateAccount(true)}
          data-aos="fade-up"
          data-aos-delay="500"
          aria-label="Open Create Account Modal"
        >
          Create Account
        </button>
        <button
          className="btn btn-secondary animated-button"
          onClick={() => setShowLogin(true)}
          data-aos="fade-up"
          data-aos-delay="700"
          aria-label="Open Login Modal"
        >
          Log in
        </button>
      </section>

      <section id="features" className="container features-grid">
        {featuresList.map((feature) => (
          <FeatureCard
            key={feature.id}
            title={feature.title}
            description={feature.description}
            image={feature.image}
            link={feature.link}
            delay={feature.delay}
          />
        ))}
      </section>

      {/* About Section */}
      <section id="about" className="container about-section" data-aos="fade-up">
        <h2>Our Mission</h2>
        <p>
          At <strong>Your Company Name</strong>, our mission is to deliver
          innovative solutions that empower businesses to achieve their goals.
          We are committed to excellence, integrity, and collaboration.
        </p>

        <h3>Our Values</h3>
        <ul className="values-list">
          <li data-aos="fade-up" data-aos-delay="100">
            <span className="icon">🚀</span>
            <strong>Innovation:</strong> We foster a culture of creativity and
            embrace new ideas to stay ahead in our industry.
          </li>
          <li data-aos="fade-up" data-aos-delay="200">
            <span className="icon">🤝</span>
            <strong>Integrity:</strong> We uphold the highest ethical standards
            in all our actions.
          </li>
          <li data-aos="fade-up" data-aos-delay="300">
            <span className="icon">💡</span>
            <strong>Customer Focus:</strong> Our customers are at the heart of
            everything we do.
          </li>
          <li data-aos="fade-up" data-aos-delay="400">
            <span className="icon">👥</span>
            <strong>Teamwork:</strong> We believe that collaboration leads to
            greater success.
          </li>
          <li data-aos="fade-up" data-aos-delay="500">
            <span className="icon">🏆</span>
            <strong>Excellence:</strong> We strive for excellence in every
            project we undertake.
          </li>
        </ul>
      </section>

      {/* Contact Section */}
      <section id="contact" className="container section-animate delay-2">
        <h2>Contact Us</h2>
        <p>Get in touch with us for any inquiries or support.</p>
      </section>

      <div className="fixed-buttons">
        <button
          className="btn btn-success animated-button"
          onClick={() => {
            // Implement Chat functionality or navigation
            toast.info('Chat feature coming soon!');
          }}
          aria-label="Open Chat"
        >
          Chat
        </button>
        <button
          className="btn btn-danger animated-button"
          onClick={() => {
            // Implement Support functionality or navigation
            toast.info('Support feature coming soon!');
          }}
          aria-label="Open Support"
        >
          Support
        </button>
      </div>

      {/* Create Account Modal */}
      <Modal
        isOpen={showCreateAccount}
        onRequestClose={() => setShowCreateAccount(false)}
        contentLabel="Create Account Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <button
          className="close"
          onClick={() => setShowCreateAccount(false)}
          aria-label="Close Create Account Modal"
        >
          &times;
        </button>
        <h2>Create Account</h2>
        <form onSubmit={handleCreateAccount}>
          <div className="form-group">
            <label htmlFor="create-name">Name:</label>
            <input
              id="create-name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleCreateInputChange}
              required
              className="form-control"
              placeholder="Your Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="create-email">Email:</label>
            <input
              id="create-email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleCreateInputChange}
              required
              className="form-control"
              placeholder="you@example.com"
            />
          </div>
          <div className="form-group">
            <label htmlFor="create-password">Password:</label>
            <input
              id="create-password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleCreateInputChange}
              required
              className="form-control"
              placeholder="Enter a secure password"
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={isSubmittingCreate}>
            {isSubmittingCreate ? 'Creating...' : 'Create Account'}
          </button>
        </form>
        {createResponseMessage && (
          <p
            className={`response ${
              createResponseMessage.toLowerCase().includes('error') ? 'error' : ''
            }`}
            role="alert"
          >
            {createResponseMessage}
          </p>
        )}
      </Modal>

      {/* Login Modal */}
      <Modal
        isOpen={showLogin}
        onRequestClose={() => setShowLogin(false)}
        contentLabel="Login Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <button
          className="close"
          onClick={() => setShowLogin(false)}
          aria-label="Close Login Modal"
        >
          &times;
        </button>
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label htmlFor="login-email">Email:</label>
            <input
              id="login-email"
              type="email"
              name="email"
              value={loginData.email}
              onChange={handleLoginInputChange}
              required
              className="form-control"
              placeholder="you@example.com"
            />
          </div>
          <div className="form-group">
            <label htmlFor="login-password">Password:</label>
            <input
              id="login-password"
              type="password"
              name="password"
              value={loginData.password}
              onChange={handleLoginInputChange}
              required
              className="form-control"
              placeholder="Your password"
            />
          </div>
          <button type="submit" className="btn btn-success" disabled={isSubmittingLogin}>
            {isSubmittingLogin ? 'Logging in...' : 'Login'}
          </button>
        </form>
        {loginResponseMessage && (
          <p
            className={`response ${
              loginResponseMessage.toLowerCase().includes('error') ? 'error' : ''
            }`}
            role="alert"
          >
            {loginResponseMessage}
          </p>
        )}
        {/* Resend Verification Section */}
        {showResendVerification && (
          <div className="resend-verification">
            <p>Didn't receive the verification email or link expired?</p>
            <button
              className="btn btn-secondary"
              onClick={handleResendVerification}
              disabled={isSubmittingLogin}
              aria-label="Resend Verification Email"
            >
              Resend Verification Email
            </button>
          </div>
        )}
      </Modal>

      {/* Contact Modal */}
      <Modal
        isOpen={showContact}
        onRequestClose={() => setShowContact(false)}
        contentLabel="Contact Us Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <button
          className="close"
          onClick={() => setShowContact(false)}
          aria-label="Close Contact Modal"
        >
          &times;
        </button>
        <h2>Contact Us</h2>
        <form onSubmit={handleContactSubmit}>
          <div className="form-group">
            <label htmlFor="contact-name">Name:</label>
            <input
              id="contact-name"
              type="text"
              name="name"
              value={contactData.name}
              onChange={handleContactInputChange}
              required
              className="form-control"
              placeholder="Your Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact-email">Email:</label>
            <input
              id="contact-email"
              type="email"
              name="email"
              value={contactData.email}
              onChange={handleContactInputChange}
              required
              className="form-control"
              placeholder="you@example.com"
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact-subject">Subject:</label>
            <input
              id="contact-subject"
              type="text"
              name="subject"
              value={contactData.subject}
              onChange={handleContactInputChange}
              required
              className="form-control"
              placeholder="Subject"
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact-message">Message:</label>
            <textarea
              id="contact-message"
              name="message"
              value={contactData.message}
              onChange={handleContactInputChange}
              required
              className="form-control"
              placeholder="Your message..."
              rows="5"
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary" disabled={isSubmittingContact}>
            {isSubmittingContact ? 'Sending...' : 'Send Message'}
          </button>
        </form>
        {contactResponseMessage && (
          <p
            className={`response ${
              contactResponseMessage.toLowerCase().includes('error') ? 'error' : ''
            }`}
            role="alert"
          >
            {contactResponseMessage}
          </p>
        )}
      </Modal>
    </div>
  );
}

export default LandingPage;
