// src/components/SearchResults.js

import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SearchResults.css';
import { AuthContext } from '../context/AuthContext';
import { apiFetch } from '../utils/api';
import { toast, ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';

function SearchResults() {
  const { user, loading: authLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // Modal functionality
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState('');
  const messageTextareaRef = useRef(null);

  // Connection statuses
  const [connections, setConnections] = useState({});

  // Fetch connection statuses
  const fetchConnectionStatuses = useCallback(async (fetchedUsers) => {
    try {
      const userIds = fetchedUsers.map((u) => parseInt(u.id, 10));
      if (userIds.length === 0) {
        setConnections({});
        return;
      }

      const data = await apiFetch(`connections/status`, {
        method: 'POST',
        body: { userIds },
      });

      const statusMap = {};
      data.connections.forEach((conn) => {
        statusMap[conn.userId] = conn.status;
      });
      setConnections(statusMap);
    } catch (error) {
      console.error('Error fetching connection statuses:', error.message);
      toast.error('Failed to load connection statuses.');
    }
  }, []);

  // Extract query parameter
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get('query') || '';
    setQuery(queryParam);

    if (queryParam.trim() === '') {
      setUsers([]);
      setConnections({});
      setLoading(false);
      return;
    }

    // Fetch users
    const fetchUsers = async () => {
      try {
        const data = await apiFetch(`search-users?query=${encodeURIComponent(queryParam.trim())}`, {
          method: 'GET',
        });
        const usersWithIntIds = data.users.map((u) => ({
          ...u,
          id: parseInt(u.id, 10),
        }));
        setUsers(usersWithIntIds);
        await fetchConnectionStatuses(usersWithIntIds);
      } catch (err) {
        console.error('Error fetching users:', err.message);
        setError(err.message);
        setUsers([]);
        setConnections({});
        if (err.errorCode === 'UNAUTHORIZED' || err.message === 'Unauthorized') {
          navigate('/login');
        } else {
          toast.error(`Failed to load users. Error: ${err.message}`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [location.search, navigate, fetchConnectionStatuses]);

  // Focus on message textarea when modal opens
  useEffect(() => {
    if (showModal && messageTextareaRef.current) {
      messageTextareaRef.current.focus();
    }
  }, [showModal]);

  // Handlers
  const handleUserClick = useCallback(
    (user) => {
      console.log('Navigating to user profile with ID:', user.id);
      navigate(`/profile/${user.id}`); // Updated route
    },
    [navigate]
  );

  const handleConnectClick = useCallback(
    async (user) => {
      try {
        await apiFetch('connections/request', {
          method: 'POST',
          body: { targetUserId: parseInt(user.id, 10) },
        });
        setConnections((prev) => ({
          ...prev,
          [user.id]: 'pending',
        }));
        toast.success('Connection request sent!');
      } catch (error) {
        console.error('Error sending connection request:', error.message);
        toast.error(`Error: ${error.message}`);
        if (error.errorCode === 'UNAUTHORIZED' || error.message === 'Unauthorized') {
          navigate('/login');
        }
      }
    },
    [navigate]
  );

  const handleViewClick = useCallback(
    (user) => {
      navigate(`/profile/${user.id}`);
    },
    [navigate]
  );

  const handleSendMessage = useCallback(async () => {
    if (!message.trim()) {
      toast.warn('Message cannot be empty.');
      return;
    }

    try {
      await apiFetch('messages/send', {
        method: 'POST',
        body: {
          recipientEmail: selectedUser.email,
          content: message.trim(),
        },
      });
      setShowModal(false);
      setMessage('');
      toast.success('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error.message);
      toast.error(`Error: ${error.message}`);
      if (error.errorCode === 'UNAUTHORIZED' || error.message === 'Unauthorized') {
        navigate('/login');
      }
    }
  }, [message, selectedUser, navigate]);

  const handleMessageClick = useCallback(
    (user) => {
      setSelectedUser(user);
      setShowModal(true);
    },
    []
  );

  // Render States
  if (loading || authLoading) {
    return (
      <div className="search-results-page">
        <p className="loading">Loading search results...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="search-results-page">
        <p className="error-message">{error}</p>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
      </div>
    );
  }

  if (users.length === 0) {
    return (
      <div className="search-results-page">
        <div className="header">
          <h1>Search Results for "{query}"</h1>
        </div>
        <p>No users found.</p>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
      </div>
    );
  }

  // Main Render
  return (
    <div className="search-results-page">
      <div className="header">
        <h1>Search Results for "{query}"</h1>
      </div>

      {/* User List */}
      <ul className="user-list">
        {users.map((u) => (
          <li key={u.id} className="user-item">
            <div
              className="user-info"
              onClick={() => handleUserClick(u)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleUserClick(u);
              }}
            >
              <img
                src={u.avatarUrl || '/default-avatar.png'}
                alt={`${u.name}'s avatar`}
                className="avatar"
              />
              <div className="user-details">
                <h2>{u.name}</h2>
                <p>{u.bio}</p>
              </div>
            </div>
            <div className="button-group">
              <button
                className="view-button"
                onClick={() => handleViewClick(u)}
                disabled={connections[u.id] !== 'connected'}
                title={
                  connections[u.id] === 'connected'
                    ? 'View Profile'
                    : 'Connect to view profile'
                }
              >
                View
              </button>
              {connections[u.id] === 'connected' ? (
                <>
                  <button className="connect-button connected" disabled>
                    Connected
                  </button>
                  <button
                    className="message-button"
                    onClick={() => handleMessageClick(u)}
                  >
                    Message
                  </button>
                </>
              ) : connections[u.id] === 'pending' ? (
                <button className="connect-button pending" disabled>
                  Pending
                </button>
              ) : (
                <button
                  className="connect-button"
                  onClick={() => handleConnectClick(u)}
                >
                  Connect
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>

      {/* Modal for Sending Messages */}
      {showModal && selectedUser && (
        <div
          className="modal-overlay"
          onClick={() => setShowModal(false)}
          role="dialog"
          aria-modal="true"
          aria-labelledby="send-message-title"
        >
          <div
            className="modal"
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
          >
            <h2 id="send-message-title">Send a Message to {selectedUser.name}</h2>
            <textarea
              ref={messageTextareaRef}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              maxLength={100} // Enforce character limit
              className="message-textarea"
              aria-label={`Message to ${selectedUser.name}`}
            ></textarea>
            <div className="modal-buttons">
              <button
                onClick={handleSendMessage}
                disabled={!message.trim()}
                className="send-button"
                aria-label="Send message"
              >
                Send
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="cancel-button"
                aria-label="Cancel sending message"
              >
                Cancel
              </button>
            </div>
            <div
              className={`char-count ${
                100 - message.length <= 10 ? 'warning' : ''
              }`}
              aria-live="polite"
            >
              {100 - message.length} characters remaining
            </div>
          </div>
        </div>
      )}

      {/* Toast Container for Notifications */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
    </div>
  );
}

SearchResults.propTypes = {
  // Define prop types if necessary
};

export default SearchResults;
